import React, { useState, useEffect } from 'react';
import { Link} from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import {Container,Row,Col} from 'react-bootstrap';
import { navigate } from 'gatsby';
import hero_img from '../images/earthly_banner.png';


import './earthly.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import earthly_slider_one from "../images/tour/tour_slider_one.png";
import earthly_slider_two from "../images/tour/earthly_slider_two.svg";
import earthly_slider_three from "../images/tour/earthly_slider_three.svg";
import earthly_slider_four from "../images/tour/earthly_slider_four.svg";
import earthly_cap from "../images/tour/cap.svg";


class Tour extends React.Component {

  constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
      this.missionPage = this.missionPage.bind(this);
    }
    state = {
      index: 0,
      default_page:"tour",
      default_radio_selected:"all_purpose",
    }

    componentDidMount(){
        let token = localStorage.getItem("earthly_web_token");
        if(token == null){
            navigate('/login');
        }
    }
    // handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log("You selected : ",this.state.default_radio_selected);
    //     navigate('/catalog/');
    // }
    // handleSelect= (e) => {
    //   this.setState({default_radio_selected:e.target.value});
    // }
    next = () =>{
      if(this.state.index == 3){
          //navigate('/catalog/');
          this.setState({default_page:"final_page"})
      }
      this.slider.slickNext();
    }
    previous = () =>{
      this.slider.slickPrev();
    }
    beforeChange = (prev, next) => {
        this.setState({ index: next });
    };
    missionPage = () =>{
        navigate('/mission');
    }
    render(){
      const settings = {
            className: "tour_slider",
            dots: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            beforeChange: this.beforeChange
          };
        const {index,default_page,default_radio_selected} = this.state;
      return (
          <div>
            <SEO title="Tour" image={hero_img} />
            <Container fluid className="p-0" style={{overflow:"hidden"}}>
            <Row>
              <Col>
                <div className="col-12 p-0 h-100">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 col-sm-12">
                  {this.state.default_page == "tour" &&
                  <div style={{paddingTop:"10px"}}>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                      <div>
                          <div className="text-center">
                            <img src={earthly_slider_one} className="img-fluid" style={{height:"371px"}} />
                          </div>
                          <div className="text-center">
                            <h3>Works On Amazon</h3>
                          </div>
                          <div className="text-center col-lg-8 offset-lg-2 col-sm-12">
                            <p>Search and browse products like normal and see helpful tri-colored labels to make more informed purchase decisions</p>
                          </div>

                      </div>
                      <div>
                        <div className="text-center">
                          <img src={earthly_slider_two} />
                        </div>
                        <div className="text-center">
                          <h3>Earthly Recommendations</h3>
                        </div>
                        <div className="text-center col-lg-8 offset-lg-2 col-sm-12">
                          <p>The "leaf icon" shows up on the right if Earthly finds related products on Amazon that are highly rated. Click the leaf to view them</p>
                        </div>
                      </div>
                      <div>
                        <div className="text-center">
                          <img src={earthly_slider_three} />
                        </div>
                        <div className="text-center">
                          <h3>See Your Impact</h3>
                        </div>
                        <div className="text-center col-lg-8 offset-lg-2 col-sm-12">
                          <p>Stay mindful of your health and that of the planet with every purchase, and see your impact grow over time</p>
                        </div>
                        </div>
                      <div>
                        <div className="text-center">
                          <img src={earthly_slider_four} />
                        </div>
                        <div className="text-center">
                          <h3>Grow your Forest from Referrals</h3>
                        </div>
                        <div className="text-center col-lg-8 offset-lg-2 col-sm-12">
                          <p>Tell your friends and family about Earthly using your referral link and we'll <a href="https://onetreeplanted.org/collections/asia/products/indonesia">plant 2 trees</a>, one for you for sharing and one for them if they signup</p>
                        </div>
                      </div>
                    </Slider>
                    <div className="row col-12" style={{marginTop:"20px",marginBottom:"20px"}}>
                      <div style={{width:"47%",marginLeft:"10px"}}>
                        <button className={`button back_btn_tour ${index == 0 ? "btnhide":"btnshow"}`}  onClick={this.previous}>
                          Back
                        </button>
                      </div>
                      <div style={{width:"48%",textAlign:"end"}}>
                        <button className="button start_btn_tour" onClick={this.next}>
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                  }
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                {this.state.default_page == "final_page" &&
                  <div className="row mt-3">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 col-sm-12">
                    <div className="text-center">
                      <img src ={earthly_cap} alt="shopping img" />
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-6 row justify-content-center">
                        <p className="all_set_header">You are all set! Shop curiously using Earthly, and make every purchase count towards a better tomorrow</p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 row justify-content-center">
                          <p className="select_shopping_product" style={{padding:"5px;"}}>Click any of these circles and scroll through the results on Amazon.com to see Earthly in action! </p>
                        </div>
                      </div>
                    <div className="row justify-content-center">
                      <div className="col-sm-12 row justify-content-center">
                          <div className="earthly_tour_final_page">
                            <ul className="text-center justify-content-center">
                              <li>
                                  <a href="https://www.amazon.com/s?k=all+purpose+cleaner" target="_blank">
                                    <label className="d-flex align-items-center">"All purpose <br/> cleaners"</label>
                                  </a>
                              </li>
                              <li>
                                  <a href="https://www.amazon.com/s?k=shampoo" target="_blank">
                                    <label className="d-flex align-items-center">"Shampoo"</label>
                                  </a>
                              </li>
                              <li>
                                  <a href="https://www.amazon.com/s?k=moisturizer" target="_blank">
                                    <label className="d-flex align-items-center">"Moisturizer"</label>
                                  </a>
                              </li>
                              <li>
                                  <a href="https://www.amazon.com/s?k=sunscreen" target="_blank">
                                    <label className="d-flex align-items-center">"Sunscreen"</label>
                                  </a>
                              </li>
                              <li>
                                  <a href="https://www.amazon.com/s?k=vanilla+milk" target="_blank">
                                    <label className="d-flex align-items-center">"Vanilla milk"</label>
                                  </a>
                              </li>
                            </ul>
                            <div className="row text-center justify-content-center">
                                <button className="button start_btn_tour" onClick={this.missionPage}>
                                  Done
                                </button>
                            </div>
                          </div>


                      </div>
                    </div>
                    <div className="col-lg-1"></div>
                  </div>
                </div>
                }
                </div>
              </Col>
            </Row>
            </Container>

          </div>



        )

    }



}

export default Tour;
